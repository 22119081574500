import React, { useState } from "react";
import { connect } from "react-redux";
import Sidebar from "../../layout/Sidebar";
import Navbar from "../../layout/Navbar";
import AttrbiuteList from "./AttrbiuteList";
import CreateNewAttribute from "./CreateNewAttribute";

import { Layout, Breadcrumb, Row, Col } from "antd";
const { Content } = Layout;

const Attribute = () => {
  const [updatelist, setUpdatelist] = useState(true);

  return (
    <>
      <Layout className="window-frame">
        <Sidebar />
        <Layout className="site-layout">
          <Navbar />
          <Content className="main-frame-content">
            <Breadcrumb style={{ margin: "16px 0" }}>
              <Breadcrumb.Item>Attribute</Breadcrumb.Item>
              <Breadcrumb.Item>All</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background main-frame">
              <Row>
                <Col span={24}>
                  {/* <CreateNewAttribute
                    setUpdatelist={setUpdatelist}
                    updatelist={updatelist}
                  /> */}
                  <h3>Attributes</h3>
                  <AttrbiuteList
                    updatelist={updatelist}
                    setUpdatelist={setUpdatelist}
                  />
                </Col>
              </Row>
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default connect()(Attribute);
