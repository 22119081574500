import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import history from "../../history";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getProductSearchResult } from "../../actions/productDetails";

import { DeleteOutlined } from "@ant-design/icons";

import { createPurchase, createPurchaseItem } from "../../actions/purchase";
import { updateVariationfromSrock } from "../../actions/variableProductAction";
import { getAllWarehouse } from "../../actions/warehouseAction";
import { getAllAccount } from "../../actions/accountsAction";
import { getAllLocation } from "../../actions/warehouseAction";

// import AddService from "./AddService";
// import AddShipping from "./AddShipping";
import CreateNewProduct from "./CreateNewProduct";
import ContctSearch from "./ContctSearch";

import {
  Divider,
  AutoComplete,
  Row,
  Col,
  Image,
  InputNumber,
  Affix,
  Button,
  message,
  DatePicker,
  Space,
  Select,
  Input,
  TreeSelect,
} from "antd";
const { Option } = Select;

const SearchStock = ({
  setfullpageloading,
  getProductSearchResult,
  List,
  WarehouseList,
  Contacts,
  search,
  cartItems,
  setCart,
  cart,
  getAllAccount,
  createPurchase,
  updateVariationfromSrock,
  Auth,
  getAllWarehouse,
  createPurchaseItem,
  getAllLocation,
  location,
}) => {
  const initial = { Note: "" };
  const services = useRef([]);
  const load = useRef(false);
  var formatter = new Intl.NumberFormat("en-IN");
  const [accounts, setaccounts] = useState([]);
  const [servicetrigger, setservicetrigger] = useState(false);
  const [locationList, setlocationList] = useState([]);
  var currentdate = new Date();
  function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment(currentdate, "YYYY-MM-DD");
  }
  var invoiceNumber =
    currentdate.getDate().toString() +
    (currentdate.getMonth() + 1).toString() +
    currentdate.getFullYear().toString() +
    currentdate.getHours().toString() +
    currentdate.getMinutes().toString() +
    currentdate.getSeconds().toString();
  const bill = useRef({
    Contact: null,
    ContactBalance: 0,
    Discount: 0,
    subTotal: 0,
    Total: 0,
    Total_product: 0,
    Due: 0,
    Paid: 0,
    PaymentMethod: "Cash",
    AccountNumber: "",
    Issue: "",
    Deliver: "",
    InvoiceNumber: invoiceNumber,
    location: Auth.profile.branch.id,
    Account_no: "",
    remarks: "",
    reference: "",
    Location: null,
    Adjustment: 0,
  });
  useEffect(() => {
    getAllAccount().then((result) => {
      setaccounts(result);
    });
    getAllLocation().then((result) => {
      setlocationList(result);
    });
  }, []);

  useEffect(() => {
    let Total = 0;
    let totoal_product = 0;
    cartItems.current.map((item) => {
      Total += parseFloat(item.total_price);
      totoal_product += parseFloat(item.quantity);
    });
    bill.current.Total = parseFloat(Total);
    bill.current.Total_product = parseFloat(totoal_product);
    if (bill.current.Discount > 0) {
      bill.current.Total =
        parseFloat(bill.current.Total) - parseFloat(bill.current.Discount);
    } else {
      bill.current.Total = parseFloat(bill.current.Total) - parseFloat(0);
    }

    bill.current.Due =
      parseFloat(bill.current.Total) -
      parseFloat(bill.current.Paid) -
      parseFloat(bill.current.Adjustment);
  }, [cart, load.current]);

  const submitOrder = () => {
    if (bill.current.Due == 0 || bill.current.Due > 0) {
      const formData = new FormData();
      let promises = [];
      if (bill.current.PaymentMethod != "Cash") {
        formData.append("account", bill.current.AccountNumber);
      } else {
        for (let i = 0; i < accounts.length; i++) {
          if (accounts[i].type == bill.current.PaymentMethod) {
            formData.append("account", accounts[i].id);
            bill.current.AccountNumber = accounts[i].id;
          }
        }
      }
      if (
        bill.current.Contact == null ||
        bill.current.AccountNumber == "" ||
        bill.current.Deliver == "" ||
        location.current == null
      ) {
        alert("Please Insert a Delevery Date and contact");
      } else {
        setfullpageloading(true);
        formData.append("contact", bill.current.Contact);
        formData.append("bill", parseFloat(bill.current.Total).toFixed(2));
        formData.append(
          "discount",
          parseFloat(bill.current.Discount).toFixed(2)
        );
        formData.append(
          "payment",
          parseFloat(bill.current.Paid + bill.current.Adjustment).toFixed(2)
        );
        formData.append(
          "adjustment",
          parseFloat(bill.current.Adjustment).toFixed(2)
        );
        formData.append("due", parseFloat(bill.current.Due).toFixed(2));
        formData.append("Payment_method", bill.current.PaymentMethod);
        formData.append("issue_date", bill.current.Issue);
        formData.append("delivery_date", bill.current.Deliver);
        formData.append("purchase_number", bill.current.InvoiceNumber);
        formData.append("remarks", bill.current.remarks);
        formData.append("reference", bill.current.reference);
        formData.append("location", location.current);
        formData.append("employe", Auth.profile.id);
        formData.append("data", "");
        createPurchase(formData).then(function (result) {
          async function someProcedure() {
            for (let i = 0; i < cartItems.current.length; i++) {
              const formData = new FormData();
              formData.append("purchase", result.id);
              formData.append("product", cartItems.current[i].id);
              formData.append("price", cartItems.current[i].price);
              formData.append("quantity", cartItems.current[i].quantity);
              formData.append("data", "");
              promises.push(createPurchaseItem(formData));
            }

            // cartItems.current.map((item) => {
            //   const formData = new FormData();
            //   formData.append("purchase", result.id);
            //   formData.append("product", item.id);
            //   formData.append("data", "");
            //   var newstock = item.limit + item.quantity;
            //   formData.append("purchase_price", item.price);
            //   formData.append("quantity", newstock);
            //   updateVariationfromSrock(item.id, formData, newstock);
            // });
            return "done";
          }
          someProcedure().then(() => {
            Promise.all(promises).then((r) => {
              message.success("Order has been taken");
              history.push("/Purchase");
            });
          });
        });
      }
    }
  };

  return (
    <>
      <Row style={{ minHeight: "23vh" }}>
        <Col span={15}>
          <ContctSearch
            bill={bill}
            setservicetrigger={setservicetrigger}
            setCart={setCart}
          />
        </Col>
        <Col span={9}>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Row style={{ margin: "auto" }}>
              <Col span={11} style={{ textAlign: "right" }}>
                <h3>Order No.</h3>
              </Col>
              <Col span={12} offset={1}>
                <InputNumber
                  value={bill.current.InvoiceNumber}
                  disabled
                  style={{ color: "black" }}
                />
              </Col>
            </Row>
            <Row style={{ margin: "auto" }}>
              <Col span={11} style={{ textAlign: "right" }}>
                <h3>
                  <span style={{ color: "red" }}>*</span>Delivery date
                </h3>
              </Col>
              <Col span={12} offset={1}>
                <DatePicker
                  disabledDate={disabledDate}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    if (e != null) {
                      bill.current.Deliver = e.format("YYYY-MM-DD");
                    }
                    setCart(true);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={11} style={{ textAlign: "right" }}>
                <h3>
                  <span style={{ color: "red" }}>*</span>Warehouse
                </h3>
              </Col>
              <Col span={12} offset={1}>
                <Select
                  style={{ width: "100%" }}
                  onChange={(value) => {
                    location.current = value;
                  }}
                >
                  {locationList.map((item) => {
                    if (!item.is_office && !item.is_outlet) {
                      return <Option value={item.id}>{item.name}</Option>;
                    }
                  })}
                </Select>
              </Col>
            </Row>
            <Row style={{ margin: "auto" }}>
              <Col span={11} style={{ textAlign: "right" }}>
                <h3>Reference</h3>
              </Col>
              <Col span={12} offset={1}>
                <Input
                  style={{ width: "100%" }}
                  onChange={(value) => {
                    bill.current.reference = value.target.value;
                    setCart(true);
                  }}
                />
              </Col>
            </Row>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={11}>
          <h1>Description</h1>
        </Col>

        <Col span={3} offset={1} style={{ textAlign: "center" }}>
          <h1>Quantity</h1>
        </Col>
        <Col span={3} style={{ textAlign: "center" }}>
          <h1>Price</h1>
        </Col>
        <Col span={5} style={{ textAlign: "center" }}>
          <h1> Total (BDT) </h1>
        </Col>
      </Row>
      {cartItems.current.map((item) => {
        const index = cartItems.current.indexOf(item);
        return (
          <>
            <Row
              style={{
                borderRadius: "10px",
                padding: "10px",
                background: "#F0F2F5",
                marginBottom: "2px",
              }}
            >
              <Col span={11}>
                <p>{item.title}</p>
                <p>
                  color: {item.color} / size: {item.size}
                </p>
              </Col>
              <Col span={3} style={{ textAlign: "center", margin: "auto" }}>
                <InputNumber
                  min={1}
                  value={cartItems.current[index].quantity}
                  onChange={(e) => {
                    cartItems.current[index].quantity = e;
                    cartItems.current[index].total_price =
                      e * cartItems.current[index].price;

                    setCart(true);
                  }}
                  defaultValue={1}
                />
              </Col>

              <Col span={3} style={{ textAlign: "center", margin: "auto" }}>
                <InputNumber
                  value={cartItems.current[index].price}
                  onChange={(e) => {
                    cartItems.current[index].price = e;
                    cartItems.current[index].total_price =
                      e * cartItems.current[index].quantity;

                    setCart(true);
                  }}
                />
              </Col>
              {/* <Col span={3} style={{ textAlign: "center", margin: "auto" }}>
                {formatter.format(item.landing_price)}
              </Col> */}
              <Col span={5} style={{ textAlign: "center", margin: "auto" }}>
                {formatter.format(item.total_price)}
                <DeleteOutlined
                  style={{ color: "Red", float: "right", margin: "auto" }}
                  onClick={(e) => {
                    cartItems.current.splice(index, 1);
                    setCart(true);
                  }}
                />
              </Col>
            </Row>
          </>
        );
      })}
      <Row>
        <Col span={11}>
          <h1></h1>
        </Col>

        <Col span={3} offset={1} style={{ textAlign: "center" }}>
          <h1>Total : {formatter.format(bill.current.Total_product)}</h1>
        </Col>
        <Col span={3} style={{ textAlign: "center" }}>
          <h1></h1>
        </Col>
        <Col span={5} style={{ textAlign: "center" }}>
          <h1>{formatter.format(bill.current.Total)}</h1>
        </Col>
      </Row>
      <Row>{search()}</Row>
      <CreateNewProduct
        cartItems={cartItems}
        bill={bill}
        setCart={setCart}
        load={load}
        location={location}
      />
      <Row>
        <Col span={14}>
          <br></br>
          <br></br>

          <h3>Note: </h3>
          <ReactQuill
            theme="snow"
            style={{ height: "20vh" }}
            onChange={(e) => (bill.current.remarks = e)}
          />
        </Col>
        <Col span={10} style={{ marginTop: "1rem" }}>
          <Space direction="vertical" style={{ width: "100%" }}>
            {/* <Row>
              <Col span={11} style={{ textAlign: "right" }}>
                <h3>Total </h3>
              </Col>
              <Col span={12} offset={1} style={{ textAlign: "center" }}>
                <InputNumber
                  value={bill.current.Total}
                  disabled
                  style={{ color: "black" }}
                />
              </Col>
            </Row> */}
            <Row>
              <Col span={11} style={{ textAlign: "right" }}>
                <h3> Discount</h3>
              </Col>
              <Col span={12} offset={1} style={{ textAlign: "center" }}>
                <InputNumber
                  max={bill.current.Total}
                  onChange={(e) => {
                    if (e > 0) {
                      bill.current.Discount = e;
                      setCart(true);
                    } else {
                      bill.current.Discount = 0;
                      setCart(true);
                    }
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={11} style={{ textAlign: "right" }}>
                <h3> Total</h3>
              </Col>
              <Col span={12} offset={1} style={{ textAlign: "center" }}>
                <InputNumber
                  value={bill.current.Total}
                  disabled
                  style={{ color: "black" }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={11} style={{ textAlign: "right" }}>
                <h3>Payment method</h3>
              </Col>
              <Col span={12} offset={1}>
                <Select
                  defaultValue="Cash"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    bill.current.PaymentMethod = e;
                    bill.current.AccountNumber = "";
                    setCart(true);
                  }}
                >
                  <Option value="Cash">Cash</Option>
                  <Option value="Bank">Bank</Option>
                  <Option value="Mobile banking">Mobile banking</Option>
                </Select>
              </Col>
            </Row>
            <Row>
              {bill.current.PaymentMethod != "Cash" ? (
                <>
                  <Col span={11} style={{ textAlign: "right" }}>
                    <h3>
                      <span style={{ color: "red" }}>*</span>Account
                    </h3>
                  </Col>
                  <Col span={12} offset={1}>
                    <Select
                      value={bill.current.AccountNumber}
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        bill.current.AccountNumber = e;
                        setCart(true);
                      }}
                    >
                      {accounts.map((account) => {
                        if (account.type == bill.current.PaymentMethod) {
                          return (
                            <Option value={account.id}>{account.name}</Option>
                          );
                        }
                      })}
                    </Select>
                  </Col>
                </>
              ) : (
                ""
              )}
            </Row>
            {bill.current.PaymentMethod == "Cash" ? (
              ""
            ) : (
              <Row>
                <Col span={11} style={{ textAlign: "right" }}>
                  <h3>Account no.</h3>
                </Col>
                <Col span={12} offset={1} style={{ textAlign: "center" }}>
                  <InputNumber
                    onChange={(e) => {
                      bill.current.Account_no = e;
                    }}
                  />
                </Col>
              </Row>
            )}
            {bill.current.PaymentMethod == "Cash" ? (
              ""
            ) : (
              <Row>
                <Col span={11} style={{ textAlign: "right" }}>
                  <h3>Transection ID.</h3>
                </Col>
                <Col span={12} offset={1} style={{ textAlign: "center" }}>
                  <InputNumber
                    onChange={(e) => {
                      bill.current.transection = e;
                    }}
                  />
                </Col>
              </Row>
            )}
            {/* {bill.current.ContactBalance < 0 ? (
              <>
                <Row>
                  <Col span={11} style={{ textAlign: "right" }}>
                    <h3>Payment Adjustment</h3>
                  </Col>
                  <Col span={12} offset={1} style={{ textAlign: "center" }}>
                    <InputNumber
                      defaultValue={0}
                      min={0}
                      max={Math.abs(bill.current.ContactBalance)}
                      onChange={(e) => {
                        console.log(e);
                        if (e != null) {
                          bill.current.Adjustment = e;
                          setservicetrigger(true);
                          setCart(true);
                        } else {
                          bill.current.Adjustment = 0;
                          setservicetrigger(true);
                          setCart(true);
                        }
                      }}
                    />
                    <small>
                      Advance Payment :{" "}
                      {formatter.format(Math.abs(bill.current.ContactBalance))}{" "}
                      BDT
                    </small>
                  </Col>
                </Row>
              </>
            ) : (
              <></>
            )} */}
            <Row>
              <Col span={11} style={{ textAlign: "right" }}>
                <h3>Payment</h3>
              </Col>
              <Col span={12} offset={1} style={{ textAlign: "center" }}>
                <InputNumber
                  defaultValue={0}
                  min={0}
                  max={bill.current.Total}
                  onChange={(e) => {
                    console.log(e);
                    if (e != null) {
                      bill.current.Paid = e;
                      setservicetrigger(true);
                      setCart(true);
                    } else {
                      bill.current.Paid = 0;
                      setservicetrigger(true);
                      setCart(true);
                    }
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={11} style={{ textAlign: "right" }}>
                <h3>Due</h3>
              </Col>
              <Col span={12} offset={1} style={{ textAlign: "center" }}>
                <InputNumber
                  defaultValue={0}
                  min={0}
                  value={bill.current.Due}
                  disabled
                  style={{ color: "black" }}
                />
              </Col>
            </Row>
            {/* <Row>
              <Col span={11} style={{ textAlign: "right" }}>
                <h3>Payment method</h3>
              </Col>
              <Col span={12} offset={1}>
                <Select
                  defaultValue="Cash"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    bill.current.PaymentMethod = e;
                    setCart(true);
                  }}
                >
                  <Option value="Card">Card</Option>
                  <Option value="Bkash">Bkash</Option>
                  <Option value="Nagad">Nagad</Option>
                  <Option value="Cash">Cash</Option>
                  <Option value="Bank">Bank</Option>
                </Select>
              </Col>
            </Row> */}
            <Button
              type="primary"
              onClick={submitOrder}
              style={{ float: "right" }}
            >
              Confirm Purchase
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    List: state.ProductDetails.productdetails,
    Contacts: state.contacts.contactlistsearchresult,
    WarehouseList: state.warehouse.warehouselist,
    Auth: state.auth,
  };
};

export default connect(mapStateToProps, {
  getProductSearchResult,
  getAllLocation,
  createPurchase,
  updateVariationfromSrock,
  getAllAccount,
  getAllWarehouse,
  createPurchaseItem,
})(SearchStock);
