import React, { useState } from "react";
import { connect } from "react-redux";
import List from "./List";
import Create from "./Create";

const Maincontent = () => {
  const [updatelist, setUpdatelist] = useState(false);

  return (
    <>
      <div className="site-layout-background main-frame">
        <Create updatelist={updatelist} setUpdatelist={setUpdatelist} />
        <List updatelist={updatelist} setUpdatelist={setUpdatelist} />
      </div>
    </>
  );
};

export default connect()(Maincontent);
