import React, { Component } from "react";
import { connect } from "react-redux";
import Sidebar from "../../layout/Sidebar";
import Navbar from "../../layout/Navbar";
import SearchStock from "./SearchStock";

import { Layout, Breadcrumb } from "antd";
const { Content } = Layout;

class Dashboard extends Component {
  render() {
    return (
      <>
        <Layout className="window-frame">
          <Sidebar />
          <Layout className="site-layout">
            <Navbar />
            <Content className="main-frame-content">
              <Breadcrumb style={{ margin: "16px 0" }}>
                <Breadcrumb.Item>Stock Valuation</Breadcrumb.Item>
              </Breadcrumb>
              <div className="site-layout-background main-frame">
                <SearchStock />
              </div>
            </Content>
          </Layout>
        </Layout>
      </>
    );
  }
}

export default connect()(Dashboard);
