import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { createContact } from "../../../actions/contactAction";
import { getcontacttype } from "../../../actions/settings";
import {
  Form,
  Input,
  InputNumber,
  Checkbox,
  Drawer,
  Button,
  Col,
  Row,
  Select,
  message,
  DatePicker,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

const CreateNewContact = ({
  createContact,
  setUpdatelist,
  ContactList,
  getcontacttype,
}) => {
  const initial = { remarks: "" };
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const investment = useRef(0);
  const period = useRef(0);
  const interest = useRef(0);

  const showDrawer = () => {
    getcontacttype();
    setVisible(true);
  };

  const onClose = () => {
    form.resetFields();
    setVisible(false);
  };

  const onFinish = (values) => {
    values.Type = "Investment Facilities";
    createContact(values);
    form.resetFields();
    setUpdatelist(false);
    // message.success(values.name + " Has been added to your contact list");
    setVisible(false);
  };

  const onInvestmentCahnge = (value) => {
    investment.current = value;
    if (
      investment.current != 0 &&
      interest.current != 0 &&
      period.current != 0
    ) {
      // INTEREST PAYABLE (MONTHLY)
      let monthlyInterest = parseFloat(
        (investment.current * (interest.current / 100)) / 12
      ).toFixed(2);

      // let yearlyInterest = parseFloat(monthlyInterest * 12).toFixed(2);

      // INVESTMENT RETURN (MONTHLY)
      let monthlyReturn = parseFloat(investment.current / 12).toFixed(2);

      // TOTAL INTEREST PAYABLE
      let interestPaid = parseFloat(monthlyInterest * period.current).toFixed(
        2
      );

      // TOTAL LIABILITIES
      let totalReturn = parseFloat(
        parseFloat(interestPaid) + parseFloat(investment.current)
      ).toFixed(2);

      form.setFieldsValue({
        // yearly_interest: yearlyInterest,
        monthly_return: monthlyReturn,
        monthly_interest: monthlyInterest,
        total_return: totalReturn,
        interest_paid: interestPaid,
      });
    }
  };
  const onInterestCahnge = (value) => {
    interest.current = value;
    if (
      investment.current != 0 &&
      interest.current != 0 &&
      period.current != 0
    ) {
      // INTEREST PAYABLE (MONTHLY)
      let monthlyInterest = parseFloat(
        (investment.current * (interest.current / 100)) / 12
      ).toFixed(2);

      // let yearlyInterest = parseFloat(monthlyInterest * 12).toFixed(2);

      // INVESTMENT RETURN (MONTHLY)
      let monthlyReturn = parseFloat(investment.current / 12).toFixed(2);

      // TOTAL INTEREST PAYABLE
      let interestPaid = parseFloat(monthlyInterest * period.current).toFixed(
        2
      );

      // TOTAL LIABILITIES
      let totalReturn = parseFloat(
        parseFloat(interestPaid) + parseFloat(investment.current)
      ).toFixed(2);

      form.setFieldsValue({
        // yearly_interest: yearlyInterest,
        monthly_return: monthlyReturn,
        monthly_interest: monthlyInterest,
        total_return: totalReturn,
        interest_paid: interestPaid,
      });
    }
  };
  const onPeriodCahnge = (value) => {
    period.current = value;
    if (
      investment.current != 0 &&
      interest.current != 0 &&
      period.current != 0
    ) {
      // INTEREST PAYABLE (MONTHLY)
      let monthlyInterest = parseFloat(
        (investment.current * (interest.current / 100)) / 12
      ).toFixed(2);

      // let yearlyInterest = parseFloat(monthlyInterest * 12).toFixed(2);

      // INVESTMENT RETURN (MONTHLY)
      let monthlyReturn = parseFloat(investment.current / 12).toFixed(2);

      // TOTAL INTEREST PAYABLE
      let interestPaid = parseFloat(monthlyInterest * period.current).toFixed(
        2
      );

      // TOTAL LIABILITIES
      let totalReturn = parseFloat(
        parseFloat(interestPaid) + parseFloat(investment.current)
      ).toFixed(2);

      // console.log("investment: " + investment.current);
      // console.log("interest: " + interestPaid);
      // console.log("TOTAL LIABILITIES: " + totalReturn);

      form.setFieldsValue({
        // yearly_interest: yearlyInterest,
        monthly_return: monthlyReturn,
        monthly_interest: monthlyInterest,
        total_return: totalReturn,
        interest_paid: interestPaid,
      });
    }
  };

  return (
    <>
      <>
        <Button
          type="primary"
          onClick={showDrawer}
          style={{ marginBottom: "10px", float: "right" }}
        >
          <PlusOutlined /> New Investment Facility
        </Button>
        <Drawer
          title="Create a new investment facility"
          width={720}
          onClose={onClose}
          visible={visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={initial}
          >
            {/* <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="name" label="Name">
                  <Input placeholder="Please enter user name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="email" label="Email">
                  <Input
                    style={{ width: "100%" }}
                    addonBefore="@"
                    placeholder="Please enter Email"
                  />
                </Form.Item>
              </Col>
            </Row> */}
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="phone"
                  label="CONTACT PERSON"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a unique name",
                    },
                  ]}
                >
                  <Input placeholder="Please enter a unique name" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="role"
                  label="LOAN TYPE"
                  rules={[
                    {
                      required: true,
                      message: "Please choose a type",
                    },
                  ]}
                >
                  <Select placeholder="Please choose the type">
                    {ContactList.map((contact) => {
                      if (contact.Type == "Investment Facilities") {
                        return (
                          <Option value={contact.id}>{contact.name}</Option>
                        );
                      }
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="investment"
                  label="INVESTMENT"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a investment amount",
                    },
                  ]}
                >
                  <InputNumber onChange={onInvestmentCahnge} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="interest" label="INTEREST RATE">
                  <InputNumber onChange={onInterestCahnge} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="period"
                  label="NO. OF MONTH (ROLLING INVESTMENT)"
                >
                  <InputNumber onChange={onPeriodCahnge} />
                </Form.Item>
              </Col>
              {/* <Col span={12}>
                <Form.Item name="yearly_interest" label="YEALRY INTEREST">
                  <InputNumber />
                </Form.Item>
              </Col> */}
              <Col span={12}>
                <Form.Item
                  name="monthly_interest"
                  label="INTEREST PAYABLE (MONTHLY)"
                >
                  <InputNumber />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="monthly_return"
                  label="INVESTMENT RETURN (MONTHLY)"
                >
                  <InputNumber />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name="interest_paid" label="TOTAL INTEREST PAYABLE">
                  <InputNumber />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="total_return" label="TOTAL LIABILITIES">
                  <InputNumber />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="address" label="ADDRESS">
                  <Input placeholder="please enter Address" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="remarks" label="REMARKS">
                  <ReactQuill theme="snow" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="is_active" valuePropName="checked" checked>
                  <Checkbox>Active !</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button onClick={onClose} style={{ marginRight: 8 }}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Drawer>
      </>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    ContactList: state.contacts.contacttype,
  };
};

export default connect(mapStateToProps, { createContact, getcontacttype })(
  CreateNewContact
);
