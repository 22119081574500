import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Sidebar from "../../layout/Sidebar";
import Navbar from "../../layout/Navbar";
import List from "./List";
import CreateNewChart from "./CreateNewChart";
import {
  Layout,
  Breadcrumb,
  Row,
  Col,
  Divider,
  Space,
  Popconfirm,
  Button,
  message,
} from "antd";

const { Content } = Layout;

const Index = ({}) => {
  const [reload, setreload] = useState(false);

  const renderview = () => {
    return (
      <Row>
        <Col span={24}>
          <Button
            type="primary"
            style={{ float: "right", marginBottom: "10px" }}
          >
            <Link to="/accounting/paymentvoucher/create">
              Create new payment voucher
            </Link>
          </Button>
        </Col>
        <Col span={24}>
          <List reload={reload} setreload={setreload} />
        </Col>
      </Row>
    );
  };
  return (
    <>
      <Layout className="window-frame">
        <Sidebar />
        <Layout className="site-layout">
          <Navbar />
          <Content className="main-frame-content">
            <Breadcrumb style={{ margin: "16px 0" }}>
              <Breadcrumb.Item>Accounting</Breadcrumb.Item>
              <Breadcrumb.Item>Payment voucher</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background main-frame">
              {renderview()}
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default connect(null)(Index);
