import * as types from "../types";
import backend from "../api/api";
import history from "../history";
import { message } from "antd";

export const signIn = (logInformValues) => async (dispatch) => {
  try {
    const response = await backend.post("api/contact/login/", {
      ...logInformValues,
    });
    dispatch({ type: types.SIGN_IN, payload: response.data });
    history.push("/dashboard");
    return true;
  } catch (error) {
    message.error("Unable to log in with provided credentials");
  }
};

export const activelink = (Menu) => async (dispatch) => {
  dispatch({ type: types.Active, Menu: Menu });
};

// export const signUp = (formValues) => async (dispatch) => {
//   try {
//     await backend.post("v1/user/data/", {
//       ...formValues,
//     });

//     const { username, password } = formValues;

//     dispatch(signIn({ username, password }));
//   } catch (error) {
//     let response = error.response.data;

//     Object.entries(response).forEach(([key, value]) => {
//       response[key] = value[0];
//     });

//     dispatch({
//       type: types.AUTH_FAILED,
//       payload: response,
//     });
//   }
// };

export const signOut = () => (dispatch) => {
  dispatch({ type: types.SIGN_OUT });
  history.push("/login");
};
