import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

// import { createStore, applyMiddleware } from "redux";
// import thunk from "redux-thunk";

import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";

import App from "./components/App";
// import reducers from "./reducers";

// const store = createStore(reducers, applyMiddleware(thunk));
ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
