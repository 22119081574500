import React from "react";
import { connect } from "react-redux";
import { deleteProduct } from "../../../actions/variableProductAction";

import { Col, Row, Select, message, Button, Popconfirm } from "antd";

const { Option } = Select;

const RemoveProduct = ({ details, deleteProduct, setreload }) => {
  return (
    <>
      <Popconfirm
        title="Are you sure to Remove this product?"
        onConfirm={(confirm) => {
          deleteProduct(details.id).then((res) => {
            message.success("Successfully removed");
            setreload(true);
          });
        }}
        okText="Yes"
        cancelText="No"
      >
        <Button type="link" danger>
          Remove
        </Button>
      </Popconfirm>
    </>
  );
};

export default connect(null, { deleteProduct })(RemoveProduct);
