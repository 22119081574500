import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import Sidebar from "../../layout/Sidebar";
import Navbar from "../../layout/Navbar";
import { getpettycashSearchResult } from "../../../actions/accounting/pettycash";
import { getAllLocation } from "../../../actions/warehouseAction";
import dateFormat from "dateformat";
import moment from "moment";
import ReactToPrint from "react-to-print";

import {
  Layout,
  Breadcrumb,
  Row,
  Col,
  DatePicker,
  TimePicker,
  Space,
  Select,
  Divider,
  Skeleton,
  Card,
  Button,
  AutoComplete,
  Spin,
} from "antd";
const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Option } = Select;

const PettyCashReport = ({
  getpettycashSearchResult,
  getAllLocation,
  businessprofile,
  Auth,
}) => {
  var formatter = new Intl.NumberFormat("en-IN");
  const componentRef = useRef();
  const [data, setData] = useState([]);
  const [outletlist, setoutletlist] = useState([]);
  const [loading, setloading] = useState(true);
  const [reload, setreload] = useState(true);
  const [type, setType] = useState("date");
  const from = useRef("2021-01-01");
  const to = useRef("2071-01-01");
  const start = useRef("");
  const end = useRef("");
  const total = useRef(0);
  const location = useRef(
    Auth.profile.branch.is_outlet ? Auth.profile.branch.id : ""
  );

  useEffect(() => {
    total.current = 0;
    setloading(true);
    getAllLocation().then((result) => {
      setoutletlist(result);
    });

    getpettycashSearchResult(
      "",
      location.current,
      start.current,
      end.current
    ).then((result) => {
      setData(result);
      total.current = 0;
      result.map((item) => {
        if (item.increase) {
          total.current += parseFloat(item.amount);
        } else {
          total.current += parseFloat(item.amount);
        }
      });
      setloading(false);
    });
  }, [reload]);

  const SwitchablePicker = () => {
    return (
      <Row wrap={false}>
        <Col flex="auto">
          Issue date :<br></br>
          <RangePicker
            style={{ width: "100%" }}
            picker={type}
            onChange={(value) => {
              if (value) {
                from.current = value[0].format("YYYY-MM-DD");
                to.current = value[1].format("YYYY-MM-DD");
                start.current = value[0].format("YYYY-MM-DD") + "T00:00:00";
                end.current = value[1].format("YYYY-MM-DD") + "T23:59:59";
                setreload(!reload);
              }
            }}
          />
          {/* <Select value={type} onChange={setType} style={{ width: "20%" }}>
            <Option value="date">Date</Option>

            <Option value="month">Month</Option>

            <Option value="year">Year</Option>
          </Select> */}
        </Col>
        <Col flex="auto">
          Location :
          <Select
            placeholder="Please select a outlet"
            style={{ width: "100%" }}
            defaultValue={Auth.profile.branch.id}
            disabled={
              Auth.superuser || Auth.profile.user_role.id == 10 ? false : true
            }
            onChange={(e) => {
              console.log(e);
              location.current = e;
              setreload(!reload);
            }}
          >
            <Option value="">All</Option>
            {outletlist.map((outlet) => {
              return <Option value={outlet.id}>{outlet.name}</Option>;
            })}
          </Select>
        </Col>
        <Col flex="auto">
          {/* <Excelldownload data={data} data1={data1} /> */}
          .
          <ReactToPrint
            trigger={() => (
              <Button style={{ width: "100%" }} type="primary">
                Print Ledger
              </Button>
            )}
            content={() => componentRef.current}
          />
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Layout className="window-frame">
        <Sidebar />
        <Layout className="site-layout">
          <Navbar />
          <Content className="main-frame-content">
            <Breadcrumb style={{ margin: "16px 0" }}>
              <Breadcrumb.Item>Petty cash Report</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background main-frame">
              <Row>
                <Col
                  span={24}
                  style={{ padding: "1rem", border: "1px solid whitesmoke" }}
                >
                  {SwitchablePicker()}
                  <Divider />
                  <Spin spinning={loading}>
                    <div ref={componentRef} style={{ padding: "10px" }}>
                      <h2 style={{ textAlign: "center" }}>NAZAARA</h2>
                      <h3 style={{ textAlign: "center" }}>Petty Cash Report</h3>
                      {start.current != "" ? (
                        <p style={{ textAlign: "center" }}>
                          {" "}
                          From{" "}
                          <b>
                            {dateFormat(start.current, "mmmm dS, yyyy")}
                          </b> to{" "}
                          <b>{dateFormat(end.current, "mmmm dS, yyyy")}</b>
                        </p>
                      ) : (
                        ""
                      )}
                      <table
                        className="account_table"
                        style={{ width: "100%" }}
                      >
                        <thead>
                          <tr style={{ background: "#f0f0f0" }}>
                            <td style={{ textAlign: "center" }}>Date</td>
                            <td style={{ textAlign: "center" }}>Employee</td>
                            <td style={{ textAlign: "center" }}>Narration</td>
                            <td style={{ textAlign: "center" }}>Amount</td>
                            <td style={{ textAlign: "center" }}>Location</td>
                          </tr>
                        </thead>

                        {data.map((item) => {
                          return (
                            <tr>
                              <td style={{ textAlign: "center" }}>
                                {dateFormat(
                                  item.created,
                                  "mmmm dS, yyyy, hh:mm"
                                )}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {item.Employee_name}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <div
                                  className="d-div"
                                  dangerouslySetInnerHTML={{
                                    __html: item.narration,
                                  }}
                                ></div>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {item.increase
                                  ? item.amount
                                  : "(" + item.amount + ")"}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {item.Location_name}
                              </td>
                            </tr>
                          );
                        })}
                        <tr>
                          <td colSpan={3} style={{ textAlign: "center" }}>
                            <b>Total</b>
                          </td>

                          <td style={{ textAlign: "center" }}>
                            <b>{total.current}</b>
                          </td>
                          <td style={{ textAlign: "center" }}>-</td>
                        </tr>
                      </table>
                      <Row style={{ minHeight: "60px" }}>
                        <Col span={12} style={{ textAlign: "left" }}>
                          {/* <img
                        src={businessprofile.signature}
                        style={{
                          maxHeight: "60px",
                          left: "0",
                        }}
                      /> */}
                        </Col>
                        <Col span={12} style={{ textAlign: "center" }}></Col>
                      </Row>
                      <Row>
                        <Col span={12} style={{ textAlign: "left" }}>
                          <h3
                            style={{
                              borderTop: "2px solid black",
                              display: "inline-block",
                            }}
                          >
                            ACCOUNT SIGNATURE
                          </h3>
                        </Col>
                        <Col span={12} style={{ textAlign: "right" }}>
                          <h3
                            style={{
                              borderTop: "2px solid black",
                              display: "inline-block",
                            }}
                          >
                            AUTHORIZER SIGNATURE
                          </h3>
                        </Col>
                      </Row>
                    </div>
                  </Spin>
                </Col>
              </Row>
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    businessprofile: state.settings.businessprofile,
    Auth: state.auth,
  };
};

export default connect(mapStateToProps, {
  getAllLocation,
  getpettycashSearchResult,
})(PettyCashReport);
