import axios from "axios";

let baseUrl;

if (window.location.origin === "http://localhost:3000") {
  baseUrl = "http://127.0.0.1:8000/";
  // baseUrl = "https://testapi2.theicthub.com/";
} else {
  //production
  baseUrl = window.location.origin;
  // baseUrl = "https://testapi2.theicthub.com/";
}
export default axios.create({
  baseURL: baseUrl,
});
