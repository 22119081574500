import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { Spin } from "antd";
import EmployeeList from "./EmployeeList";
import CreateNewEmployee from "./CreateNewEmployee";
import Excelldownload from "./Excelldownload";

const Maincontent = () => {
  const [updatelist, setUpdatelist] = useState(true);
  const [data, setData] = useState([]);
  // const loading = useRef(true);
  const [loading, setloading] = useState(true);

  return (
    <>
      <div className="site-layout-background main-frame">
        <Excelldownload data={data} data1={data} />
        <CreateNewEmployee
          setUpdatelist={setUpdatelist}
          updatelist={updatelist}
        />
        <Spin spinning={loading}>
          <EmployeeList
            loading={loading}
            setloading={setloading}
            updatelist={updatelist}
            setUpdatelist={setUpdatelist}
            setData={setData}
          />
        </Spin>
      </div>
    </>
  );
};

export default connect()(Maincontent);
