import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import Sidebar from "../../layout/Sidebar";
import Navbar from "../../layout/Navbar";
import ReactToPrint from "react-to-print";

import moment from "moment";
import { getAllAccount } from "../../../actions/accountsAction";
import { getContactSearchResult } from "../../../actions/contactAction";
import { getJournalSearchResult } from "../../../actions/journalAction";
import {
  getSpecificcontacttype,
  getcontacttype,
} from "../../../actions/settings";

import {
  Layout,
  Breadcrumb,
  Row,
  Col,
  DatePicker,
  Space,
  Select,
  Divider,
  Skeleton,
  Button,
  Spin,
  Collapse,
} from "antd";
const { Panel } = Collapse;
const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Option } = Select;

const Loan = ({
  getJournalSearchResult,
  getContactSearchResult,
  getSpecificcontacttype,
  getcontacttype,
}) => {
  const componentRef = useRef();
  const [journals, setJournals] = useState([]);
  const [loans, setloans] = useState([]);
  const [loantype, setloantype] = useState([]);
  const [loading, setloading] = useState(true);
  const [reload, setreload] = useState(true);
  var formatter = new Intl.NumberFormat("en-IN");

  useEffect(() => {
    setloading(true);
    getcontacttype().then((loantyperesult) => {
      console.log(loantyperesult);
      setloantype(loantyperesult);
    });
    getContactSearchResult("", "Investment Facilities").then((res) => {
      getJournalSearchResult(
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "200020002,200020001,200020003,400020027,200030001,200030002,200030003"
      ).then((result) => {
        setloans(res);
        console.log(result);
        setJournals(result.reverse());
        setloading(false);
      });
    });
  }, [reload]);

  const SwitchablePicker = () => {
    return (
      <Row>
        <Col flex="auto">
          <ReactToPrint
            trigger={() => <Button type="primary">Print Report</Button>}
            content={() => componentRef.current}
          />
        </Col>
      </Row>
    );
  };

  const loanRender = (loan) => {
    let totalloan = 0;
    let totalpaid = 0;
    let totalinterest = 0;
    for (let i = 0; i < journals.length; i++) {
      if (journals[i].contact == loan.id) {
        if (journals[i].Group == "Liabilities") {
          if (journals[i].increase) {
            totalloan += parseFloat(journals[i].amount);
          } else {
            totalpaid += parseFloat(journals[i].amount);
          }
        } else if (journals[i].Group == "Expense") {
          if (journals[i].increase) {
            totalinterest += parseFloat(journals[i].amount);
          } else {
            totalinterest -= parseFloat(journals[i].amount);
          }
        }
      }
    }
    return (
      <>
        <tr>
          <td>-</td>
          <td>{loan.phone}</td>
          <td style={{ textAlign: "right" }}>
            {formatter.format(loan.investment)}
          </td>
          <td style={{ textAlign: "right" }}>
            {formatter.format(loan.interest)}%
          </td>
          <td style={{ textAlign: "right" }}>
            {formatter.format(loan.period)}
          </td>
          <td style={{ textAlign: "right" }}>{formatter.format(totalpaid)}</td>
          <td style={{ textAlign: "right" }}>
            {formatter.format(totalinterest)}
          </td>
          <td style={{ textAlign: "right" }}>
            {formatter.format(loan.total_return)}
          </td>
          <td style={{ textAlign: "right" }}>
            {formatter.format(totalloan - totalpaid)}
          </td>
          <td style={{ textAlign: "right" }}>
            {formatter.format(loan.interest_paid - totalinterest)}
          </td>
          <td style={{ textAlign: "right" }}>
            {formatter.format(loan.total_return - (totalpaid + totalinterest))}
          </td>
        </tr>
      </>
    );
  };

  const Rendercontent = () => {
    return (
      <>
        <Row>
          <Col
            span={24}
            style={{ padding: "1rem", border: "1px solid whitesmoke" }}
          >
            {SwitchablePicker()}
            <Divider />
            <Spin spinning={loading}>
              <div ref={componentRef} style={{ width: "100%" }}>
                <table className="history_table">
                  <tr style={{ backgroundColor: "yellow" }}>
                    <td>CATEGORIES</td>
                    <td>PARTICULARS</td>
                    <td>LOAN AMOUNT</td>
                    <td>INTEREST</td>
                    <td>PERIOD </td>
                    <td>LOAN PAID</td>
                    <td>INTEREST PAID</td>
                    <td>TOTAL LIABILITIES</td>
                    <td>REMAINING LOAN AMOUNT</td>
                    <td>REMAINING INTEREST AMOUNT</td>
                    <td>REMAINING TOTAL LIABILITIES</td>
                  </tr>

                  {loantype.map((type) => {
                    if (type.Type == "Investment Facilities") {
                      return (
                        <>
                          <tr style={{ backgroundColor: "#ffecc9" }}>
                            <td colSpan={11}>{type.name}</td>
                          </tr>
                          {loans.map((loan) => {
                            if (loan.Role == type.name) return loanRender(loan);
                          })}
                        </>
                      );
                    }
                  })}
                </table>

                <br></br>
              </div>
            </Spin>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Layout className="window-frame">
        <Sidebar />
        <Layout className="site-layout">
          <Navbar />
          <Content className="main-frame-content">
            <Breadcrumb style={{ margin: "16px 0" }}>
              <Breadcrumb.Item>Loan status</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background main-frame">
              {Rendercontent()}
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    businessprofile: state.settings.businessprofile,
    locationlist: state.warehouse.locationlist,
  };
};

export default connect(mapStateToProps, {
  getJournalSearchResult,
  getContactSearchResult,
  getAllAccount,
  getSpecificcontacttype,
  getcontacttype,
})(Loan);
