import React, { useState } from "react";
import { connect } from "react-redux";
import Sidebar from "../../layout/Sidebar";
import Navbar from "../../layout/Navbar";
import AddPaySlip from "./AddPaySlip";

import { Layout, Breadcrumb, Row, Col, Select, Divider } from "antd";
const { Content } = Layout;
const { Option } = Select;

const AddPayslip = () => {
  const [updatelist, setUpdatelist] = useState(false);

  return (
    <>
      <Layout className="window-frame">
        <Sidebar />
        <Layout className="site-layout">
          <Navbar />
          <Content className="main-frame-content">
            <Breadcrumb style={{ margin: "16px 0" }}>
              <Breadcrumb.Item>PaySlip</Breadcrumb.Item>
              <Breadcrumb.Item>Add</Breadcrumb.Item>
            </Breadcrumb>

            <div className="site-layout-background main-frame">
              <AddPaySlip
                setUpdatelist={setUpdatelist}
                updatelist={updatelist}
              />
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default connect()(AddPayslip);
