import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import {
  Skeleton,
  Tree,
  Row,
  Col,
  Divider,
  Spin,
  DatePicker,
  Select,
  Input,
  Space,
  Button,
} from "antd";
import { getlog } from "../../../actions/userlogAction";
import Rendertable from "./Rendertable";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;

const List = ({ getlog }) => {
  const [data, setdata] = useState({ results: [], count: 0 });
  const [loading, setloading] = useState(true);
  const [reload, setreload] = useState(true);
  const pageno = useRef(1);
  const page_size = useRef(10);
  const start = useRef("");
  const end = useRef("");
  const action = useRef("");
  const created_by = useRef("");
  const model = useRef("");

  useEffect(() => {
    getlog(
      pageno.current,
      page_size.current,
      "",
      "",
      start.current,
      end.current,

      action.current
    ).then((result) => {
      console.log(result.results);
      setdata(result);
      setloading(false);
    });
  }, [reload]);

  const SwitchablePicker = () => {
    return (
      <Row style={{ width: "100%" }}>
        <Col span={5}>
          <RangePicker
            picker="date"
            onChange={(value) => {
              if (value) {
                start.current = value[0].format("YYYY-MM-DD") + "T00:00:00";
                end.current = value[1].format("YYYY-MM-DD") + "T23:59:59";
                setreload(!reload);
              }
            }}
          />
        </Col>
        {/* <Col span={4}>
          <Select
            placeholder="Select an Account"
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: "95%" }}
            onChange={(value) => {
              if (value != "") {
                getSpecificChartofaccounts(value).then((result) => {
                  selectedchartofaccount.current = result.account_code;
                  spinning.current = true;
                  setreload(!reload);
                });
              } else {
                selectedchartofaccount.current = value;
                spinning.current = true;
                setreload(!reload);
              }
            }}
          >
            <Option value="">All</Option>;
            {charts.map((item) => {
              return <Option value={item.id}>{item.account_name}</Option>;
            })}
          </Select>
        </Col> */}

        <Col span={13}>
          <Space>
            {/* <Search
              placeholder="action"
              onChange={(value) => {
                action.current = value.target.value;
              }}
              onSearch={(value) => {
                action.current = value;

                setreload(!reload);
              }}
              style={{ width: "100%" }}
            /> */}
            {/* <Search
              placeholder="Model"
              onChange={(value) => {
                created_by.current = value.target.value;
              }}
              onSearch={(value) => {
                created_by.current = value;

                setreload(!reload);
              }}
              style={{ width: "100%" }}
            />
            <Search
              placeholder="Created by"
              onChange={(value) => {
                model.current = value.target.value;
              }}
              onSearch={(value) => {
                model.current = value;

                setreload(!reload);
              }}
              style={{ width: "100%" }}
            /> */}
          </Space>
        </Col>

        {/* <Col span={2}>
          <ReactToPrint
            trigger={() => <Button type="primary">Print Ledger</Button>}
            content={() => componentRef.current}
          />
        </Col> */}
      </Row>
    );
  };
  return (
    <>
      {SwitchablePicker()}
      <Divider />
      <Spin spinning={loading}>
        <Rendertable
          List={data}
          pageno={pageno}
          page_size={page_size}
          setreload={setreload}
          setloading={setloading}
          reload={reload}
        />
      </Spin>
    </>
  );
};

export default connect(null, {
  getlog,
})(List);
