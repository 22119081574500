import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import EmployeeLeaveList from "./EmployeeLeaveList";
import CreateNewEmployeeLeave from "./CreateNewEmployeeLeave";
import Excelldownload from "./Excelldownload";
import PdfDownload from "./PdfDownload";
import ReactToPrint from "react-to-print";
import { Button } from "antd";
import moment from "moment";

const Maincontent = () => {
  const [updatelist, setUpdatelist] = useState(true);
  const [data, setData] = useState([]);
  const componentRef = useRef();

  return (
    <>
      <div className="site-layout-background main-frame">
        <CreateNewEmployeeLeave
          setUpdatelist={setUpdatelist}
          updatelist={updatelist}
        />
        <Excelldownload data={data} data1={data} />

        <ReactToPrint
          trigger={() => (
            <Button type="primary" style={{ marginLeft: "10px" }}>
              Print this out!
            </Button>
          )}
          content={() => componentRef.current}
        />
        <PdfDownload
          data={data}
          data1={data}
          componentRef={componentRef}
          date={moment()}
        />

        <EmployeeLeaveList
          updatelist={updatelist}
          setUpdatelist={setUpdatelist}
          setData={setData}
        />
      </div>
    </>
  );
};

export default connect()(Maincontent);
