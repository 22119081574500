import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout, Form, Input, Button, Spin } from "antd";
import { signIn, signOut } from "../../actions/authAction";
import { LoadingOutlined } from "@ant-design/icons";
import "./login.css";

const { Content } = Layout;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

class Login extends Component {
  state = {
    loading: false,
  };
  onFinish = (values) => {
    this.setState({
      loading: true,
    });
    this.props.auth.email = values.username;
    this.props.signIn(values).then((e) => {
      this.setState({
        loading: false,
      });
    });
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  render() {
    return (
      <>
        <Layout
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + "/cover.jpg"})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            minHeight: "100vh",
          }}
        >
          <Content className="login-form">
            <Form
              {...layout}
              name="basic"
              initialValues={{ remember: true }}
              onFinish={this.onFinish}
              onFinishFailed={this.onFinishFailed}
            >
              <Form.Item
                label="Email"
                name="username"
                rules={[
                  { required: true, message: "Please input your username!" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input.Password />
              </Form.Item>
              {/* <Form.Item
                {...tailLayout}
                name="remember"
                valuePropName="checked"
              >
                <Checkbox>Remember me</Checkbox>
              </Form.Item> */}
              <Form.Item {...tailLayout}>
                {!this.state.loading ? (
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                ) : (
                  <Spin
                    indicator={antIcon}
                    spinning={this.state.loading}
                    delay={500}
                  ></Spin>
                )}
              </Form.Item>
            </Form>
          </Content>
        </Layout>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { signIn, signOut })(Login);
