import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";

import Rendertable from "./Rendertable";
import { getLowStockProductSearchResult } from "../../../actions/productDetails";
import { getAllLocation } from "../../../actions/warehouseAction";
import {
  Divider,
  AutoComplete,
  Skeleton,
  Row,
  Col,
  Button,
  Select,
} from "antd";

import PdfDownload from "./PdfDownload";
import Excelldownload from "./Excelldownload";
import ReactToPrint from "react-to-print";

const SearchStock = ({
  getLowStockProductSearchResult,
  Auth,
  getAllLocation,
}) => {
  const { Option } = Select;
  const [List, setList] = useState([]);
  const [loading, setloading] = useState(true);
  const [reload, setreload] = useState(false);
  const keyward = useRef("");
  // const location = useRef(Auth.superuser ? "" : Auth.profile.branch.id);
  const location = useRef(Auth.profile.branch.id);
  const [locations, setLocations] = useState([]);
  const componentRef = useRef();
  // const onChange = (data) => {
  //   setloading(true);
  //   getProductSearchResult(data, location.current).then((result) => {
  //     const arr = [];
  //     for (const item of result.results) {
  //       if (item.quantity <= item.Deatils[0].stock_alart_amount) {
  //         arr.push(item);
  //       }
  //     }
  //     setList(arr);
  //     setloading(false);
  //   });
  // };
  const onChange = (event) => {
    if (event.keyCode == 13) {
      keyward.current = event.target.value;
      setreload(!reload);
    }
  };
  useEffect(() => {
    // setloading(true);
    getAllLocation().then((result) => {
      setLocations(result);
    });
    getLowStockProductSearchResult(keyward.current, location.current).then(
      (result) => {
        console.log(result);
        const arr = [];
        for (const item of result.results) {
          if (item.quantity <= item.Deatils[0].stock_alart_amount) {
            arr.push(item);
          } else if (item.Deatils[0].stock_alart_amount == null) {
            console.log("null");
          }
        }
        setList(arr);
        setloading(false);
        // setreload(false);
      }
    );
  }, [reload]);

  const renderdata = () => {
    if (loading) {
      return <Skeleton active />;
    } else {
      return <Rendertable List={List} />;
    }
  };

  return (
    <>
      <Row>
        <Col span={10}>
          <h3>Enter barcode or product code for instant Search</h3>
          <AutoComplete
            placeholder="input search text"
            onKeyUp={onChange}
            style={{ width: "100%" }}
          />
        </Col>
        <Col span={6} offset={1}>
          <h3>Choose warehouse / outlet</h3>
          <Select
            placeholder="Please select a outlet / warehouse"
            style={{ width: "100%" }}
            defaultValue={Auth.profile.branch.id}
            disabled={
              Auth.superuser ||
              Auth.profile.user_role.id == 11 ||
              Auth.profile.user_role.id == 12
                ? false
                : true
            }
            onChange={(e) => {
              location.current = e;
              setloading(true);
              setreload(!reload);
            }}
          >
            <Option value="">All</Option>
            {locations.map((outlet) => {
              return <Option value={outlet.id}>{outlet.name}</Option>;
            })}
          </Select>
        </Col>
      </Row>
      <Divider />
      <Row gutter={24}>
        <Col span={4}>
          <Excelldownload data={List} data1={List} />
        </Col>
        <Col span={4}>
          <ReactToPrint
            trigger={() => <Button type="primary">Print this out!</Button>}
            content={() => componentRef.current}
          />
          <PdfDownload data={List} data1={List} componentRef={componentRef} />
        </Col>
      </Row>

      <Divider />
      {renderdata()}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    List: state.ProductDetails.productdetails,
    Auth: state.auth,
  };
};

export default connect(mapStateToProps, {
  getLowStockProductSearchResult,
  getAllLocation,
})(SearchStock);
