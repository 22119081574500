import * as types from "../types";
import backend from "../api/api";
import history from "../history";
import { store } from "../store";
import { message } from "antd";

const getConfig = () => {
  const token = store.getState().auth.token;

  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  return config;
};

export const createEmployee = (formValues) => async (dispatch) => {
  console.log(formValues);
  try {
    const response = await backend.post(
      "api/contact/employee/",
      formValues,
      getConfig()
    );
    if (response.status === 201) {
      dispatch(updateEmployeeProfile(response.data.profile_id, formValues), {
        type: types.CREATE_EMPLOYEE,
        payload: { ...response.data },
      });
      //message.success(formValues["name"] + " Has been added to your employee list");
    }
  } catch (error) {
    alert(error);
  }
};

export const getAllEmployee = () => async (dispatch) => {
  try {
    const response = await backend.get(
      "api/contact/employee/?is_active=true",
      getConfig()
    );
    if (response.status === 200) {
      dispatch({
        type: types.GET_ALL_EMPLOYEE,
        payload: response.data,
      });
      // console.log(response.data);
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllInactiveEmployee = () => async (dispatch) => {
  try {
    const response = await backend.get(
      "api/contact/employee/?is_active=false",
      getConfig()
    );
    if (response.status === 200) {
      // dispatch({
      //   type: types.GET_ALL_EMPLOYEE,
      //   payload: response.data,
      // });
      // console.log(response.data);
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllEmployeeByBranch = (branch) => async (dispatch) => {
  try {
    const response = await backend.get(
      `api/contact/employee/?is_active=true&branch__id=${branch}`,
      getConfig()
    );
    if (response.status === 200) {
      dispatch({
        type: types.GET_ALL_EMPLOYEE_BY_BRANCH_SHIFT,
        payload: response.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};
export const getAllEmployeeByShift = (shift) => async (dispatch) => {
  try {
    const response = await backend.get(
      `api/contact/employee/?is_active=true&employeeprofile__defaultShift=${shift}`,
      getConfig()
    );
    if (response.status === 200) {
      dispatch({
        type: types.GET_ALL_EMPLOYEE_BY_BRANCH_SHIFT,
        payload: response.data,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllEmployeeByBranchShift =
  (branch, shift) => async (dispatch) => {
    try {
      const response = await backend.get(
        `api/contact/employee/?is_active=true&branch__id=${branch}&shift=${shift}`,
        getConfig()
      );
      if (response.status === 200) {
        dispatch({
          type: types.GET_ALL_EMPLOYEE_BY_BRANCH_SHIFT,
          payload: response.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

export const deleteEmployee = (id) => async (dispatch) => {
  try {
    const response = await backend.delete(
      `api/contact/employee/${id}/`,
      getConfig()
    );
    if (response.status === 204) {
      dispatch({ type: types.DELETE_EMPLOYEE, payload: id });
    }
  } catch (error) {
    console.log(error);
  }
};

export const getSpecificEmployee = (id) => async (dispatch) => {
  try {
    const response = await backend.get(
      `api/contact/employee/${id}/`,
      getConfig()
    );

    if (response.status === 201) {
      dispatch({
        type: types.GET_SPECIFIC_EMPLOYEE,
        payload: response.data,
      });
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateEmployee = (id, formValues) => async (dispatch) => {
  console.log("in action");
  console.log(formValues);

  try {
    const response = await backend.put(
      `api/contact/employee/${id}/`,
      formValues,
      getConfig()
    );
    if (response.status === 202) {
      dispatch(updateEmployeeProfile(response.data.profile_id, formValues), {
        type: types.UPDATE_EMPLOYEE,
        payload: { ...response.data },
      });
    }
    //message.success(formValues["name"] + " Has been Updated");
  } catch (error) {
    console.log(error.response);
  }
};

export const updateEmployeeProfile = (id, formValues) => async (dispatch) => {
  formValues["is_active"] = true;
  try {
    const response = await backend.put(
      `api/contact/employee-profile/${id}/`,
      formValues,
      getConfig()
    );
    if (response.status === 202) {
      dispatch({
        type: types.UPDATE_EMPLOYEE_PROFILE,
        payload: { ...response.data },
      });
    }
    message.success("Updated Successfully");
    history.push("/employee");
  } catch (error) {
    console.log(error.response);
  }
};

export const getEmployeeSearchResult = (data) => async (dispatch) => {
  try {
    const response = await backend.get(
      `api/contact/employee/?is_active=true&search=${data}`,
      getConfig()
    );

    if (response.status === 200) {
      dispatch({
        type: types.GET_EMPLOYEE_SEARCH_RESULT,
        payload: response.data,
      });
    }
  } catch (error) {
    alert(error);
  }
};

export const getEmployeeSearchResultbyPhone = (data) => async (dispatch) => {
  try {
    const response = await backend.get(
      `api/contact/employee/?is_active=true&keyward=${data}`,
      getConfig()
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    alert(error);
  }
};
