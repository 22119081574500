import React, { Component } from "react";
import { connect } from "react-redux";
import Sidebar from "../layout/Sidebar";
import Navbar from "../layout/Navbar";
import Excelimport from "./Excelimport";

import { Layout, Breadcrumb, Divider } from "antd";
const { Content } = Layout;

class Tools extends Component {
  render() {
    return (
      <>
        <Layout className="window-frame">
          <Sidebar />
          <Layout className="site-layout">
            <Navbar />
            <Content className="main-frame-content">
              <Breadcrumb style={{ margin: "16px 0" }}>
                <Breadcrumb.Item>Import Data</Breadcrumb.Item>
              </Breadcrumb>
              <div className="site-layout-background main-frame">
                <h1>Import Products from Excel file</h1>
                <Excelimport />
              </div>
            </Content>
          </Layout>
        </Layout>
      </>
    );
  }
}

export default connect()(Tools);
