import * as types from "../../types";
import backend from "../../api/api";
import history from "../../history";
import { store } from "../../store";
import { message } from "antd";

const getConfig = () => {
  const token = store.getState().auth.token;

  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  return config;
};

export const resetLatestaccountstatus = (start, end) => async (dispatch) => {
  try {
    const response = await backend.get(
      `api/accounting/accountstatusreset/`,
      getConfig()
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getallaccountstatus = (start, end) => async (dispatch) => {
  try {
    const response = await backend.get(
      `api/accounting/accountStatusByDate/?start=${start}&end=${end}`,
      getConfig()
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};
