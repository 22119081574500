import React, { Component } from "react";
import { connect } from "react-redux";
import { Router, Route, Redirect } from "react-router-dom";
import "antd/dist/antd.css";
import "./custom.css";
import history from "../history";

import Dashboard from "./Dashboard";
import Login from "./essential/Login";

//Contact
import Contact from "./Contact";
import Supplier from "./Contact/Supplier";
import Investment from "./Contact/Investment";
import ContactOptions from "./Contact/Options/index";

//product
import Attribute from "./Product/Attribute/index";
import Category from "./Product/Category";
import AddProduct from "./Product/AddProduct";
import ProductDetails from "./Product/ProductDetails";
import ViewProduct from "./Product/ProductDetails/ViewProduct";
import ProductImport from "./Product/Options/index";

//Stock
import Stock from "./Stock/Stock";
import AlertStock from "./Stock/AlertStock";
import Transfer from "./Transfer";
import TransferHistory from "./Transfer/TransferHistory";
import TransferStock from "./Stock/TransferStock/";
import StockValuation from "./Stock/StockValuation/";
import Packaging from "./Stock/Packaging/";
import Material from "./Stock/Material/";

// Report
import salesReport from "./Report/Sales/salesReport";
import DeliveryReport from "./Report/Delivery/DeliveryReport";
import serviceReport from "./Report/Service/serviceReport";
import CurrentStock from "./Report/CurrentStock";
import PurchaseReport from "./Report/Purchase";
import vatReport from "./Report/VAT/salesReport";
import DueCollection from "./Report/DueCollection";
import PettyCashReport from "./Report/PettyCash/PettyCashReport";
import SoldProduct from "./Report/SoldProduct";
import Userlog from "./Report/Userlog";

//purchase
import Purchase from "./Purchase";
import AddPurchaseOrder from "./Purchase/add";

//Sponsorship
import Wordrobe from "./Wordrobe";
import AddWordrobeOrder from "./Wordrobe/add";
import WordrobeStock from "./Wordrobe/Stock";

//Order
import Order from "./Order";
import AddOrder from "./Order/add";
import Cupon from "./Cupon";
import OrderReport from "./Order/Report/salesReport";
import Showinvoice from "./Order/Showinvoice";
import Services from "./Services/Services";
import Invoice from "./Order/CustomerInvoice";

// Tools
import Tools from "./Tools";
import Warehouse from "./Warehouse";
import Outlet from "./Outlet";
import Office from "./Office";
import BusinessProfile from "./Settings/BusinessProfile/Index";
import ContactGroup from "./Settings/ContactGroup/Index";
import DeliveryGroup from "./Settings/DeliveryGroup/Index";
import AccountGroup from "./Settings/AccountGroup/Index";
import Permissions from "./Settings/Permissions";

// HRM
import Department from "./HRM/Department";
import userRole from "./HRM/UserRoles";
import Employee from "./HRM/Employee";
import LeaveType from "./HRM/LeaveType";
import EmployeeLeave from "./HRM/EmployeeLeave";
import EmployeeAttendance from "./HRM/Attendence";
import EmployeeAttendanceReport from "./HRM/AttendenceReport";
import EmployeeSalary from "./HRM/Salary";
import LoanManagement from "./HRM/LoanManagement";
import PaySlip from "./HRM/Payslip";
import AttendanceImport from "./HRM/Attendence/AttendanceExcelimport";
import EmployeeSalaryList from "./HRM/SalaryReport";
import AttendanceReportShort from "./HRM/AttendanceReportShort";
// Accounting
import BranchSales from "./Accounting/BranchSales";
import Chartofaccounts from "./Accounting/Chartofaccounts";
import Accounts from "./Accounting/Accounts";
import AccountsStatus from "./Accounting/Ledger/AccountsStatus";
import Journals from "./Accounting/Journals";
import JournalVoucher from "./Accounting/JournalVoucher/";
import JournalVoucherCreate from "./Accounting/JournalVoucher/CreateNewChart";
import ReveiveVoucher from "./Accounting/ReveiveVoucher";
import ReveiveVoucherCreate from "./Accounting/ReveiveVoucher/CreateNewChart";
import PaymentVoucher from "./Accounting/PaymentVoucher";
import PaymentVoucherCreate from "./Accounting/PaymentVoucher/CreateNewChart";
import ContraVoucher from "./Accounting/ContraVoucher/";
import ContraVoucherCreate from "./Accounting/ContraVoucher/CreateNewChart";
import TrailBalance from "./Accounting/TrailBalance";
import Ledger from "./Accounting/Ledger";
import CashAndBankBookLedger from "./Accounting/Ledger/CashAndBankLedger";
import PayableAndReceivable from "./Accounting/Ledger/PayableAndReceivable";
import ExpenseReport from "./Accounting/Ledger/ExpenseReport";
import IncomeStatement from "./Accounting/IncomeStatement";
import BalanceSheet from "./Accounting/BalanceSheet";
import Loan from "./Accounting/Loan";
// import IncomeAndExpense from "./Accounting/Ledger/IncomeAndExpense";
//petty cash
import PettyCash from "./PettyCash";

class App extends Component {
  constructor(props) {
    super(props);
    if (
      !this.props.auth.isSignedIn &&
      history.location.pathname.split("/", 3)[1] != "invoice"
    ) {
      history.push("/login");
    }
  }
  render() {
    return (
      <Router history={history}>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/dashboard" component={Dashboard} />

        <Route exact path="/customer" component={Contact} />
        <Route exact path="/supplier" component={Supplier} />
        <Route exact path="/investment" component={Investment} />
        <Route exact path="/contact/options" component={ContactOptions} />

        <Route exact path="/product" component={ProductDetails} />
        <Route exact path="/product/image/:id" component={ViewProduct} />
        <Route exact path="/product/Add" component={AddProduct} />
        <Route exact path="/product/attribute" component={Attribute} />
        <Route exact path="/product/category" component={Category} />
        <Route exact path="/product/import" component={ProductImport} />

        <Route exact path="/stock" component={Stock} />
        <Route exact path="/stock/packaging" component={Packaging} />
        <Route exact path="/stock/material" component={Material} />
        <Route exact path="/stock/alert" component={AlertStock} />
        <Route exact path="/stock/transfer" component={Transfer} />
        <Route
          exact
          path="/stock/transfer/history"
          component={TransferHistory}
        />
        <Route exact path="/stock/TransferStock" component={TransferStock} />

        <Route exact path="/Purchase" component={Purchase} />
        <Route exact path="/Purchase/Add" component={AddPurchaseOrder} />

        <Route exact path="/order" component={Order} />
        <Route exact path="/order/Add" component={AddOrder} />
        <Route exact path="/order/report" component={OrderReport} />
        <Route exact path="/service" component={Services} />
        <Route exact path="/cupons" component={Cupon} />
        <Route exact path="/invoice/:id" component={Invoice} />

        <Route exact path="/wordrobe" component={Wordrobe} />
        <Route exact path="/wordrobe/Add" component={AddWordrobeOrder} />
        <Route exact path="/wordrobe/stock" component={WordrobeStock} />

        <Route exact path="/report/sales" component={salesReport} />
        <Route exact path="/report/duecollection" component={DueCollection} />
        <Route exact path="/report/delivery" component={DeliveryReport} />
        <Route exact path="/report/service" component={serviceReport} />
        <Route exact path="/report/currentstock" component={CurrentStock} />
        <Route exact path="/report/alert" component={AlertStock} />
        <Route exact path="/report/sponsorship" component={AlertStock} />
        <Route exact path="/report/WordrobeStock" component={WordrobeStock} />
        <Route exact path="/report/purchase" component={PurchaseReport} />
        <Route exact path="/report/vat" component={vatReport} />
        <Route exact path="/report/StockValuation" component={StockValuation} />
        <Route
          exact
          path="/report/PettyCashReport"
          component={PettyCashReport}
        />

        <Route exact path="/tools" component={Tools} />
        <Route exact path="/office" component={Office} />
        <Route exact path="/warehouse" component={Warehouse} />
        <Route exact path="/outlet" component={Outlet} />
        <Route exact path="/businessprofile" component={BusinessProfile} />
        <Route exact path="/contactgroup" component={ContactGroup} />
        <Route exact path="/deliverygroup" component={DeliveryGroup} />
        <Route exact path="/accountgroup" component={AccountGroup} />
        <Route exact path="/pettycash" component={PettyCash} />
        <Route exact path="/permissions" component={Permissions} />

        <Route
          exact
          path="/accounting/chartofaccounts"
          component={Chartofaccounts}
        />
        <Route exact path="/accounting/accounts" component={Accounts} />
        <Route
          exact
          path="/accounting/accountstatus"
          component={AccountsStatus}
        />
        <Route exact path="/accounting/branchsales" component={BranchSales} />
        <Route exact path="/accounting/journals" component={Journals} />
        <Route
          exact
          path="/accounting/journalvoucher"
          component={JournalVoucher}
        />
        <Route
          exact
          path="/accounting/journalvoucher/create"
          component={JournalVoucherCreate}
        />
        <Route
          exact
          path="/accounting/receivevoucher"
          component={ReveiveVoucher}
        />
        <Route
          exact
          path="/accounting/receivevoucher/create"
          component={ReveiveVoucherCreate}
        />
        <Route
          exact
          path="/accounting/paymentvoucher"
          component={PaymentVoucher}
        />
        <Route
          exact
          path="/accounting/paymentvoucher/create"
          component={PaymentVoucherCreate}
        />
        <Route
          exact
          path="/accounting/contravoucher"
          component={ContraVoucher}
        />
        <Route
          exact
          path="/accounting/contravoucher/create"
          component={ContraVoucherCreate}
        />
        <Route exact path="/accounting/trailbalance" component={TrailBalance} />
        <Route exact path="/accounting/ledger" component={Ledger} />
        <Route
          exact
          path="/accounting/expensereport"
          component={ExpenseReport}
        />

        <Route
          exact
          path="/accounting/CashBankBook"
          component={CashAndBankBookLedger}
        />
        <Route
          exact
          path="/accounting/PayableAndReceivable"
          component={PayableAndReceivable}
        />
        {/* <Route
          exact
          path="/accounting/IncomeAndExpense"
          component={IncomeAndExpense}
        /> */}

        <Route
          exact
          path="/accounting/incomestatement"
          component={IncomeStatement}
        />
        <Route exact path="/accounting/balancesheet" component={BalanceSheet} />
        <Route exact path="/accounting/loanstatus" component={Loan} />

        <Route exact path="/report/soldporductreport" component={SoldProduct} />
        <Route exact path="/report/userlog" component={Userlog} />

        <Route exact path="/department" component={Department} />
        <Route exact path="/user-role" component={userRole} />
        <Route exact path="/employee" component={Employee} />
        <Route exact path="/leave-type" component={LeaveType} />
        <Route exact path="/employee-leave" component={EmployeeLeave} />
        <Route exact path="/attendance" component={EmployeeAttendance} />
        <Route
          exact
          path="/attendance-report"
          component={EmployeeAttendanceReport}
        />
        <Route exact path="/salary" component={EmployeeSalary} />
        <Route exact path="/loan-management" component={LoanManagement} />
        <Route exact path="/pay-slip" component={PaySlip} />
        <Route exact path="/import-attendance" component={AttendanceImport} />
        <Route exact path="/salary-report" component={EmployeeSalaryList} />
        <Route
          exact
          path="/attendance-report-short"
          component={AttendanceReportShort}
        />
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(App);
